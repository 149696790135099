.MainSidebar {
  width: 100px;
  height: calc(100vh - 120px);
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.MainSidebar__list {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MainSidebar__folder-link {
  display: block;
  cursor: pointer;
  text-decoration: none;
  list-style: none;
  border: 1px solid black;
  line-height: 30px;
  padding: 10px;
  width: 100px;
}
.MainSidebar__button-container {
  align-self: center;
  margin-top: 10px;
}
.MainSidebar__add-folder-button {
  background: white;
  border: 1px solid black;
  width: 80px;
  line-height: 30px;
  cursor: pointer; 
}
.active {
  background: lightblue;
}