.App__header {
  height: 120px;
  width: 100%;
  border: 1px solid black;
  padding: 40px;
}

.App__main {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 120px);
}